import React from 'react';
import places from '../../data/places.json'
import VendorInventory from "./VendorInventory";
import vendors from '../../data/vendors.json';
import './Vendor.css';

const Vendor = props => {
    const { id } = props;
    const place = places.find(place => place.id === id);
    const vendor = vendors.find(v => v.id === id);
    if (!vendor) return null;
    const { inventory } = vendor;

    return (
        <div>
            <div className="place-desc">{place.desc}</div>
            <VendorInventory inventory={inventory}/>
        </div>
    )
}

export default Vendor;