import React from 'react';
import Draggable from "react-draggable";
import {Button, Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import ErrorBoundary from "./ErrorBoundary";

const WindowWrapper = ({
    children,
    title = "Window",
    footer,
    visible= true,
    noClose= false,
    className
}) => {
    const [isVisible, setIsVisible] = React.useState(visible);

    if (!isVisible) return null;

    const closeWindow = e => setIsVisible(false);

    return (
        <Draggable>
            <Card className={`window-wrapper ${className}`}>
                <Card.Header>
                    <Card.Title>{title}</Card.Title>
                        <Button className="close-button" onClick={closeWindow}>
                            <FontAwesomeIcon className="fa-xl" icon={faCircleXmark} />
                        </Button>
                </Card.Header>
                <Card.Body className="window-content">
                    <ErrorBoundary>
                        {children}
                    </ErrorBoundary>
                </Card.Body>
                <Card.Footer>
                    {footer || null}
                </Card.Footer>
            </Card>
        </Draggable>
    );
}

export default WindowWrapper;