import {createSlice} from "@reduxjs/toolkit";
import gigsData from "../data/gigs.json";

const initialGigState = gigsData;

const gigSlice = createSlice({
    name: 'gigs',
    initialState: initialGigState,
    reducers: {
        startGig: (state, action) => {
        },
        finishGig: (state, action) => {
        },
        cancelGig: (state, action) => {
        },
        removeGig: (state, action) => {
        },
        newGig: (state, action) => {
            state.gigs.push(action.payload);
        },
        updateGig: (state, action) => {
            const { gigId, gigData } = action.payload;
            const gig = state.gigs.find(g => g.id === gigId);
            Object.keys(gigData).forEach(k => {
                gig.data[k] = gigData[k];
            })
        }
    }
});

export const {
    startGig,
    newGig,
    updateGig
} = gigSlice.actions;

export default gigSlice.reducer;