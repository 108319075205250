import {configureStore} from "@reduxjs/toolkit";
import playerReducer from "./player";
import gameReducer from "./game";
import mapReducer from "./map";
import gigReducer from "./gigs";
import marketReducer from "./market";

const store = configureStore({
    reducer: {
        player: playerReducer,
        game: gameReducer,
        map: mapReducer,
        gigs: gigReducer,
        market: marketReducer
    },
});

export default store;