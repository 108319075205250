import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {routeFrom, toggleSelect} from '../../store/map.js';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle, faCircleArrowRight, faStreetView} from "@fortawesome/free-solid-svg-icons";
import connections from "../../data/connections.json";
import places from "../../data/places.json";
import locations from "../../data/locations.json";
import {travel} from "../../store/game";
import {Card} from "react-bootstrap";
import GigList from "../Gig/GigList";
import PlacesList from "./PlaceList";

const TravelNode = props => {
    const ref = useRef(null);
    const [show, setShow] = React.useState(false);
    const dispatch = useDispatch();
    const gigState = useSelector(state => state.gigs);
    const mapState = useSelector(state => state.map);
    const {node, highlight, selected, here, travelTime } = props;

    useEffect(() => {
        document.addEventListener('click', clickOutside);
        const svgMarker = document.querySelector(`#Nodes>#${node.id}`);
        if (svgMarker) {
            const box = svgMarker.getBBox();
            const coords = {x: box.x/2+box.width/2, y: box.y/2};
            ref.current.style.left = `${coords.x}px`;
            ref.current.style.top = `${coords.y}px`;
        }
        return () => { document.removeEventListener('click', clickOutside); }
    })

    if (!node) return null;
    const nodeConnections = connections.filter(c => c.a === node.id || c.b === node.id);
    const travelModes = [...new Set(nodeConnections.map(c => c.mode))].sort();

    let nodePlaces = [];

    const nodeLocation = locations.find(location => location.id === node.id);
    if (nodeLocation && nodeLocation.places !== undefined && nodeLocation.places.length > 0) {
        nodePlaces = (nodeLocation.places.map(place => {
            const nodePlace = places.find(p => place.id === p.id);
            if (nodePlace !== undefined) nodePlace.dist = place.dist;
            return nodePlace;
        }));
    }

    const classNames = ['travelNode', node.id];
    highlight && classNames.push('highlight');
    selected && classNames.push('selected');
    here && classNames.push('here');
    nodeLocation && classNames.push('location');
    let nodeGigs = [];
    gigState.gigs.forEach(gig => {
        const gigPlace = nodePlaces.find(place => place.id === gig.place);
        if (gigPlace !== undefined) nodeGigs.push(gig);
    });


    const modeIcons = travelModes.map(mode =>
        <FontAwesomeIcon icon={faCircle} className={`tr-dot tr-${mode}`} key={mode} fixedWidth />
    );

    const clickOutside = e => {
        // if (ref.current&& !ref.current.contains(e.target)) {
        //     console.log(e.target);
        //     console.log(ref.current);
        //     dispatch(unselectNode(node));
        // }
    }

    const nodeClick = e => {
        if (!selected) {
            dispatch(toggleSelect(node));
            setShow(true);
        } else {
            setShow(!show);
        }
    }

    const goToNode = e => {
        dispatch(travel({ destination: mapState.routeTo, time: mapState.travelTime }));
        dispatch(routeFrom(node));
    }

    return (
        <Card className={classNames} ref={ref}>
            <Card.Header onClick={nodeClick}>
                <div className="modeIcons">{modeIcons}</div>
                <h1> {node.name}</h1>
                {selected && !here?
                    <div className="actions">
                        <div className="dist">{`${Math.round(travelTime.total)}min`||''}</div>
                        <FontAwesomeIcon  icon={faCircleArrowRight} className="go" onClick={goToNode} />
                    </div> : null}
                {here?
                    <div className="fa-layers fa-fw fa-lg here">
                        <FontAwesomeIcon icon={faCircle} inverse fixedWidth />
                        <FontAwesomeIcon icon={faStreetView} transform="shrink-3" fixedWidth />
                    </div> : null}
            </Card.Header>
            {nodeLocation && show?
                <Card.Body>
                    <Card.Text>{nodeLocation && nodeLocation.desc}</Card.Text>
                    <PlacesList places={nodePlaces} />
                    <GigList gigs={nodeGigs} />
                </Card.Body>
            : null}
        </Card>
    )
}


export default TravelNode;