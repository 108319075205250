import React from 'react';
import {Button, Container} from "react-bootstrap";
import poordash_p from '../../assets/poordash_p.png';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';

const LandingPage = props => {
    const navigate = useNavigate();

    const gotoMain = () => {
        navigate('/main');
    };

    return (
        <Container id="landing" className="fluid jumboTron">
            <img style={{ maxWidth: 100, maxHeight: 100 }} src={poordash_p} alt="" />
            <h1>PoorDash</h1>
            <Button id="race" onClick={gotoMain}>
                <h5>Race to the Bottom!</h5>
            </Button>
        </Container>
    )
}

export default LandingPage;
