import {createSlice} from "@reduxjs/toolkit";
import locations from '../data/places.json';

const initialMapState = {
    routeFrom: null,
    routeTo: null,
    route: null,
    routeDistance: 0,
    travelTime: 0,
    selectedLocation: locations['city-center'],
    selectedNode: null,
    layers: { 'Features': true, 'Freeways': true, 'Streets': true, 'Regions': false, 'Train': true },
    filters: { tr: false, st: true, fw: false },
    highlightedNodes: []
}

const mapSlice = createSlice({
    name: 'map',
    initialState: initialMapState,
    reducers: {
        routeTo: (state, action) => { state.routeTo = action.payload; },
        routeFrom: (state, action) => { state.routeFrom = action.payload; },
        showRoute: (state, action) => { state.showRoute = action.payload; },
        selectLocation: (state, action) => { state.selectedLocation = action.payload; },
        toggleLayer: (state, action) => { state.layers[action.payload] = !state.layers[action.payload] },
        selectNode: (state, action) => {
            state.selectedNode = action.payload;
        },
        unselectNode: (state, action) => {
            state.selectedNode = null;
            state.routeTo = null;
        },
        toggleSelect: (state, action) => {
            if (state.selectedNode && state.selectedNode.id === action.payload.id) {
                state.selectedNode = null;
                state.routeTo = null;
            } else {
                state.selectedNode = action.payload;
                state.routeTo = action.payload;
            }
        },
        setFilter: (state, action) => {
            state.filters = { tr: false, st: false, fw: false };
            state.filters[action.payload] = true;
            state.routeTo = null;
        },
        toggleFilter: (state, action) => {
            const mode = action.payload;
            state.filters[mode] = !state.filters[mode]
        },
        highlightNodes(state, action) {
            state.highlightedNodes = action.payload;
        },
        highlightNode(state, action) {
            state.highlightedNodes = state.highlightedNodes.concat(action.payload);
        },
        unhighlightNodes(state, action) {
            state.highlightedNodes = [];
        },
        setRouteDistance(state, action) {
            state.routeDistance = action.payload;
        },
        setRoute(state, action) {
            state.route = action.payload;
        },
        setTravelTime(state, action) {
            state.travelTime = action.payload;
        }
    }
});

export const {
    routeFrom,
    routeTo,
    showRoute,
    selectNode,
    unselectNode,
    toggleSelect,
    toggleLayer,
    selectLocation,
    setFilter,
    toggleFilter,
    highlightNodes,
    highlightNode,
    unhighlightNodes,
    setRouteDistance,
    setRoute,
    setTravelTime
} = mapSlice.actions;

export default mapSlice.reducer;