import Table from 'react-bootstrap/Table';
import items from '../../data/items';
import { ReactComponent as ChitIcon } from '../../assets/chitIcon.svg'

const VendorInventory = ({ inventory }) => {
    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Item</th>
                    <th>Price</th>
                    <th>qty</th>
                </tr>
            </thead>
            <tbody>
            {inventory.map((invItem, i) => {
                const itemId = invItem.id;
                const itemQty = invItem.qty;
                const itemPrice = invItem.price
                const item = items.find(item => item.id === itemId);
                return (
                    <tr key={item.id}>
                        <td className="itemName">{item.name}</td>
                        <td className="itemPrice"><ChitIcon className="chit-icon" />{itemPrice}</td>
                        <td className="itemQty">{itemQty}</td>
                    </tr>
                );
            })}
            </tbody>
        </Table>
    )
}

const getInvItem = itemId => items.find(item => item.id === itemId);

export default VendorInventory;