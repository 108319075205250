import React, {useEffect, useRef} from 'react';
import {useDispatch} from "react-redux";
import {highlightNode, selectNode, unhighlightNodes} from "../../store/map";
import locations from "../../data/locations.json";
import {findNodeFromLocation} from "./LocationList";

const PlaceList = ({ places }) => {
    return (
        <div className="places-list">
            <ul>
                {places.map((place, i) => <PlaceItem key={i} place={place} />)}
            </ul>
        </div>
    )
}

const PlaceItem = ({ place, i }) => {
    const ref = useRef(null);
    const dispatch = useDispatch();

    const placeNode = findNodeFromPlace(place);

    const handleHover = e => {
        placeNode && dispatch(highlightNode(placeNode.id));
    }

    const handleUnhover = e => {
        dispatch(unhighlightNodes());
    }

    const handleClick = e => {
        placeNode && dispatch(selectNode(placeNode));
    }

    useEffect(() => {
        if(ref.current === null ) {
            ref.current.addEventListener('mouseover', handleHover);
            return () => ref.current.removeEventListener('mouseover', handleHover);
        }
    }, [handleHover]);

    return (
        <li key={i}
            onMouseEnter={handleHover}
            onMouseLeave={handleUnhover}
            onClick={handleClick}
            ref={ref}>
            {place.name}
        </li>
    );
}

export const findLocationFromPlace = place => {
    return locations.find(location => {
        if (typeof location.places === "undefined" || !location.places || !location.places.length) return false;
        const placeIds = location.places.map(p => p.id);
        return placeIds.indexOf(place.id) !== -1;
    });
}

export const findNodeFromPlace = place => {
    const location = findLocationFromPlace(place);
    return location && findNodeFromLocation(location);
}


export default PlaceList;