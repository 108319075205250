import React from 'react';
import CityMapFilters from "./CityMapFilters";
import {Accordion, Stack, Tab, Tabs} from "react-bootstrap";
import GigList, {payAmountWithSymbol} from "../Gig/GigList";
import PlaceList from "./PlaceList";
import places from '../../data/places.json'
import LocationList from "./LocationList";
import locations from "../../data/locations.json"
import {useSelector} from "react-redux";
import WindowWrapper from "../ui/WindowWrapper";
import Table from "react-bootstrap/Table";
import vendors from "../../data/vendors.json";
import { ReactComponent as ChitIcon } from '../../assets/chitIcon.svg'

const PlacesHere = ({ places }) => (
    <Table bordered striped hover>
        <thead>
        <tr><th>Place</th></tr>
        </thead>
        <tbody>
        {places.map(place => (
            <tr key={place.id}>
                <td>{place.name}</td>
            </tr>
        ))}
        </tbody>
    </Table>
)

const VendorsHere = ({ vendors }) => (
    <Table bordered striped hover>
        <thead>
        <tr>
            <th>Vendor</th>
            <th>Type</th>
        </tr>
        </thead>
        <tbody>
        {vendors.map(vendor => (
            <tr key={vendor.id}>
                <td>{vendor.name}</td>
                <td>{vendor.type}</td>
            </tr>
        ))}
        </tbody>
    </Table>
)

const GigsHere = ({ gigs }) => (
    <Table bordered striped hover>
        <thead>
        <tr>
            <th>Gig</th><th>Type</th><th>Place</th><th>Pay</th>
        </tr>
        </thead>
        <tbody>
        {gigs.map((gig, i) => {
            const gigPlace = places.find(pl => pl.id === gig.place);
            const {amount, symbol} = payAmountWithSymbol(gig.pay);
            return (
                <tr key={i}>
                    <td>{gig.title}</td>
                    <td>{gig.type}</td>
                    <td>{gigPlace.name}</td>
                    <td className="itemPrice"><ChitIcon className="chit-icon"/><span>{amount}</span>
                    </td>
                </tr>
            )
        })}
        </tbody>
    </Table>
);

const CityMapKey = props => {
    const gameState = useSelector(state => state.game);
    const gigState = useSelector(state => state.gigs);

    const { currentLocation, currentTime } = gameState;
    if (!currentLocation) return null;
    const timeString = new Date(currentTime).toLocaleString("en-US");
    const { gigs } = gigState;
    const gigTime = 0; // todo: placeholder
    const locationObj = locations.find(location => location.id === currentLocation);
    const placesHere = getPlacesAt(locationObj);
    const vendorsHere = getVendorsAt(locationObj);
    const gigsHere = getGigsAt(locationObj, gigs);

    const HereTab = () => (
        <div className="hereTab">
            <div className="locationDesc">{locationObj.desc}</div>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        Places Here
                    </Accordion.Header>
                    <Accordion.Body>
                        <PlacesHere places={placesHere} />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        Vendors Here
                    </Accordion.Header>
                    <Accordion.Body>
                        <VendorsHere vendors={vendorsHere}/>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>
                        Gigs Here
                    </Accordion.Header>
                    <Accordion.Body>
                        <GigsHere gigs={gigsHere} />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );

    const JetTab = () => (
        <div className="jetTab">
            <Table bordered striped hover>
                <thead>
                <tr>
                    <th>Location</th>
                </tr>
                </thead>
                <tbody>
                {locations.map((location, i) =>
                    <tr key={location.id}>
                        <td>{location.name} </td>
                    </tr>
                )}
                </tbody>
            </Table>
        </div>
    )

    return (
        <WindowWrapper className="city-map-key" title={timeString}>
            <Stack gap={2} direction="vertical">
                <div class="gigInfo">
                    <div className="gigTime low">{gigTime ? `${gigTime}min remaining` : "No current Gig"}</div>
                </div>
                <CityMapFilters/>
                <Tabs defaultActiveKey="locations" fill>
                    <Tab eventKey="here" title="Here">
                        <HereTab/>
                    </Tab>
                    <Tab eventKey="locations" title="Locations">
                        <LocationList locations={locations} currentLocation={currentLocation}/>
                    </Tab>
                    <Tab eventKey="places" title="Places">
                        <PlaceList places={places}/>
                    </Tab>
                    <Tab eventKey="gigs" title="Gigs">
                        <GigList gigs={gigs}/>
                    </Tab>
                    <Tab eventKey="jet" title="Jet">
                        <JetTab />
                    </Tab>
                </Tabs>
            </Stack>
        </WindowWrapper>
    );
};

const getPlacesAt = (locationObj) => {
    if (typeof locationObj === "undefined" || !locationObj['places']) return [];
    const locationPlaceIds = locationObj.places.map((place => place.id ));
    return places.filter(place => locationPlaceIds.indexOf(place.id) !== -1);
}

const getVendorsAt = (locationObj) => {
    const placesAt = getPlacesAt (locationObj);
    const placeIds = placesAt.map(p => p.id);
    return vendors.filter(vendor => placeIds.indexOf(vendor.id));
}

const getGigsAt = (locationObj, gigs) => {
    const placesAt = getPlacesAt(locationObj);
    const placeIds = placesAt.map(p => p.id);
    return gigs.filter(gig => placeIds.indexOf(gig.place) !== -1);
}

export default CityMapKey;