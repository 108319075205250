import React, {useEffect} from 'react';
import WindowWrapper from "./WindowWrapper";
import Vendor from "../Vendor/Vendor";

const Windows = () => {

    const bringToFront = e => {
        e.currentTarget.classList.add("front");
    }

    useEffect(() => {
        document.querySelectorAll('.window-wrapper')
            .forEach(w => { w.addEventListener('click', bringToFront) });
        return () => { }
    })
    return (
        <div className="windows">
            <WindowWrapper title="Cosa Nostra Pizza">
                <Vendor id="cosa-nostra-pizza"/>
            </WindowWrapper>
        </div>
    )
}

export default Windows;