import {Link} from "react-router-dom";
import {Nav, Navbar} from "react-bootstrap";
import Socials from "../Socials";
import './ui.css';

const Footer = props =>
    <footer>
        <Navbar>
            <Nav>
                <Link to="/about">About</Link>
                {/*<Link to="/investors">Investors</Link>*/}
                {/*<Link to="/press">Press</Link>*/}
                <Link to="/contact">Contact</Link>
                {/*<Link to="/jobs">Careers</Link>*/}
                {/*<Link to="/signup">Sign Up</Link>*/}
                {/*<Link to="/login">Log In</Link>*/}
                {/*<Link to="/privacy">Privacy</Link>*/}
                {/*<Link to="/news">News</Link>*/}
                <Socials />
            </Nav>
        </Navbar>
        <div className="satire">
            <b>This website is a work of satire.</b><br/>
            It is not a real business and does not offer any commercial services.<br/>
            If you are trying to order real food or earn real money,
            you may want to try a different app.
        </div>
    </footer>

export default Footer;
