import React from "react";
import {Container} from "react-bootstrap";
import './Main.css';
import CityMap from "../../components/CityMap";
import Windows from "../../components/ui/Windows";

const MainPage = () => {
    return (
        <Container fluid={true} id="main">
            <CityMap />
            <Windows />
        </Container>
    );
};

export default MainPage;
