import {createSlice} from "@reduxjs/toolkit";

const initialGameState = {
    level: 1,
    startedAt: 0,
    currentLocation: 'city-center',
    currentTime: new Date(2029, Math.floor(Math.random()*12), Math.floor(Math.random()*28+1)).getTime()
}

const gameSlice = createSlice({
    name: 'game',
    initialState: initialGameState,
    reducers: {
        nextLevel: state => { state.level += 1 },
        travel: (state, action) => {
            state.currentLocation = action.payload.destination.id;
            state.currentTime += action.payload.time;
        },
        startGame: (state, action) => { state.startedAt = action.payload },
        incrementTime: (state, action) => { state.currentTime += action.payload },
    }
});

export const {
    nextLevel,
    travel,
    startGame,
    incrementTime
} = gameSlice.actions;

export default gameSlice.reducer;