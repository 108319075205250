import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import TravelNode from "./TravelNode";
import nodeConnections from '../../data/connections.json';
import travelNodes from '../../data/travelNodes.json';
import {calculateTotalTravelTime, calculateTravelTime, weightedDijkstra} from "../../util/Utils";
import {setTravelTime} from "../../store/map";

const CityMapOverlay = props => {
    const dispatch = useDispatch();
    const mapState = useSelector(state => state.map);
    const gameState = useSelector(state => state.game);
    const { currentLocation } = gameState;

    const selectedNode = mapState.selectedNode || currentLocation;
    const currentNode = travelNodes.find(loc => loc.id === currentLocation);
    const routeFrom = currentNode;
    const routeTo = selectedNode;

    let route = {nodes: [], connections: []};
    let distance = 0;
    let travelTime = 0;

    if (routeFrom && routeTo && routeFrom !== routeTo) {
        const { filters } = mapState;
        const mode = Object.keys(mapState.filters).filter(k => filters[k] === true)[0];

        route = findShortestRoute(routeFrom, routeTo, mode);
        // distance = route.connections.map(conn => conn.dist).reduce((acc, dist) => acc+dist, 0);
        travelTime = calculateTotalTravelTime(route);
        const { segments, total }  = travelTime;
        dispatch(setTravelTime(total));
    }

    useEffect(() => {
        const { filters } = mapState;
        const mode = Object.keys(mapState.filters).filter(k => filters[k] === true)[0];
        document.querySelectorAll('svg .highlight')
            .forEach(item => item.classList.remove('highlight'));

        if (route && route.nodes.length > 0) {
            let { nodes, connections } = route;
            const paths = connections.map(c => c.path).flat();
            paths.forEach(conn => {
                const seg = document.querySelector(`#${conn}`);
                if (seg) seg.classList.add('highlight');
            });
            nodes.forEach(node => {
                const nodeMarker = document.querySelector(`.travelNode.${node}`);
                if (nodeMarker) {
                    nodeMarker.classList.add('highlight');
                    nodeMarker.classList.add(`hl-${mode}`);
                }
            });
            connections.forEach(conn => {
                const seg = conn.path.map(segId => document.querySelector(`#${segId}`))
                    .reduce((longestSeg, curSeg) => {
                        if (!longestSeg) return curSeg;
                        if (!longestSeg.getTotalLength() < curSeg.getTotalLength()) {
                            return curSeg;
                        }
                        return longestSeg;
                });
                const length = seg.getTotalLength();
                const midPoint = seg.getPointAtLength(length/2);
                const coords = {x: midPoint.x/2, y: midPoint.y/2};
                coords.x = coords.x - 5;
                coords.y = coords.y - 5;
                const id = `${conn.a}-${conn.b}`;
                const segLabel = document.querySelector(`.${id}`);
                if (segLabel) {
                    segLabel.style.left = `${coords.x}px`;
                    segLabel.style.top = `${coords.y}px`;
                }
            })
        }
    })

    return (
        <div className="cityMapOverlay">
            <div className="travelNodes">
                {travelNodes.map((node, i) => {
                    const highlight = mapState.highlightedNodes.indexOf(node.id) !== -1;
                    const selected = selectedNode.id === node.id;
                    const here = currentNode === node;
                    return (
                        <TravelNode key={i}
                                    node={node}
                                    selected={selected}
                                    here={here}
                                    distance={distance}
                                    highlight={highlight}
                                    travelTime={travelTime}
                        />
                    );
                })}
            </div>

            <div className="connections">
                {route && route.connections.map((conn, i) => {
                    const travelTime = calculateTravelTime(conn);
                    return (
                        <div key={i} className={`connectionLabel ${conn.a}-${conn.b}`}>
                            <span>{travelTime}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

const filterConnections = (connections, mode) => {
    const streetConnections = connections.filter(connection => connection.mode === 'st');
    const fastConnections = connections.filter(connection => connection.mode === mode);
    let filteredConnections = structuredClone(fastConnections);
    streetConnections.forEach(sc => {
        let fc = findConnection(sc.a, sc.b, mode);
        if (!fc) filteredConnections.push(sc);
    });
    return filteredConnections;
}

const findShortestRoute = (startNode, endNode, transportMode) => {
    let graph = {}
    travelNodes.forEach(node => graph[node.id] = {});
    const filteredConnections = filterConnections(nodeConnections, transportMode);
    filteredConnections.forEach(c => {
        const dist = (c.dist || 100) * (c.mode === 'st' ? 1.5 : 1); // todo: hacky - fix
        if (c.a && c.b) {
            const conn = {
                id: `${c.a}_${c.b}_${c.mode}`,
                dist,
                path: c.path || [],
                mode: c.mode || 'st'
            }
            if (graph[c.a][c.b] === undefined) graph[c.a][c.b] = {};
            if (graph[c.b][c.a] === undefined) graph[c.b][c.a] = {};
            graph[c.a][c.b] = conn;
            graph[c.b][c.a] = conn;
        }
    });

    Object.keys(graph).forEach(key => { if (Object.keys(graph[key]).length === 0) delete graph[key] });
    let pathNodes = [];
    if (Object.keys(graph).length && startNode.id && endNode.id) {
        if (Object.keys(graph).indexOf(startNode.id) > -1 && Object.keys(graph).indexOf(endNode.id) > -1) {
            pathNodes = weightedDijkstra(graph, startNode.id, endNode.id);
        }
    }

    const nodes = structuredClone(pathNodes);
    const connections = connectionsFromNodes(pathNodes, transportMode);
    return { connections, nodes };
}

const findConnection = (a, b, mode = 'st') =>
    nodeConnections.filter(c => c.mode === mode)
        .find(c => c.mode === mode && ((c.a === a && c.b === b) || (c.b === a && c.a === b)));

const connectionsFromNodes = (nodes, mode) => {
    let path = [];
    let currentNode;
    let prevNode = nodes.shift();
    while (nodes.length > 0) {
        currentNode = nodes.shift();
        let connection = findConnection(prevNode, currentNode, mode);
        if (!connection) connection = findConnection(prevNode, currentNode, 'st'); // todo: hacky, fix

        path.push(connection);
        prevNode = currentNode;
    }
    return path;
}

const travelTo = (node, route) => null;

export default CityMapOverlay;