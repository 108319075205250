import {createSlice} from "@reduxjs/toolkit";

const initialMarketState = {
    chitToCred: 0.01,
    CredToChit: 100.0,
    prices: {
        "gas": 100.00,
        "ammo": 50,
        "energy": 25,
        "water": 5,
        "protein": 5
    }

}

const marketSlice = createSlice({
    name: 'market',
    initialState: initialMarketState,
    reducers: {
        updateMarket: (state, action) => {
            Object.keys(state.market.prices).forEach(key => {
                state.market.prices[key] += Math.random()*10-5;
            })
        }
    }
});

export const {
    marketUpdate
} = marketSlice.actions;

export default marketSlice.reducer;