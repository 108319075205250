import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    name: 'Deliverator',
    pronouns: 'they/them',
    health: 100,
    experience: 0,
    credits: 1000,
    chits: 1000,
    inventory: [],
    carry: 20
};

const playerSlice = createSlice ({
    name: 'player',
    initialState,
    reducers: {
        setName: (state, action) => { state.name = action.payload; },
        setPronouns: (state, action) => { state.pronouns = action.payload; },
        setCarry: (state, action) => { state.carry = action.payload; },
        setHealth: (state, action) => { state.health = action.payload; },
        adjHealth: (state, action) => { state.health += action.payload; },
        gainExp: (state, action) => { state.experience += action.payload; },
        adjCredits: (state, action) => { state.credits += action.payload; },
        adjChits: (state, action) => { state.chits += action.payload; },
        addItem: (state, action) => {
            state.inventory.push(action.payload);
        },
        removeItem: (state, action) => {
            const index = state.inventory.findIndex(item => item === action.payload);
            if (index !== -1) state.inventory.splice(index, 1);
        }
    }
});

export const {
    setName,
    setPronouns,
    setCarry,
    setHealth,
    adjHealth,
    adjCredits,
    adjChits,
    gainExp,
    addItem,
    removeItem
} = playerSlice.actions;

export default playerSlice.reducer;
