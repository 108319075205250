import Table from "react-bootstrap/Table";
import React from "react";
import places from "../../data/places.json";
import {findLocationFromPlace} from "../CityMap/PlaceList";
import {findNodeFromLocation} from "../CityMap/LocationList";
import {faCentSign, faEuroSign} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const GigList = ({ gigs }) => {
    if (!gigs || !gigs.length) return null;

    return (
        <Table striped hover size="sm" variant="dark" className="gig-list">
            <thead>
            <tr>
                <th className="gig-type">Type</th>
                <th>Title</th>
                <th>Reward</th>
                <th>Place</th>
                <th>Time</th>
            </tr>
            </thead>
            <tbody>
            {gigs.map((gig, i) => {
                const { amount, symbol } = payAmountWithSymbol(gig.pay);

                return (
                    <tr key={i}>
                        <td className="gig-type">{gig.type}</td>
                        <td className="gig-title">{gig.title}</td>
                        <td className="gig-pay"><span>{amount}</span><FontAwesomeIcon icon={symbol} /></td>
                        <td className="gig-place">{gig.place.name}</td>
                        <td className="gig-time">{gig.time}</td>
                    </tr>
                )
            })}
            </tbody>
        </Table>
    );
}

export const findGigPlace = gig => places.find(place => gig.place === place.id);

export const findGigLocation = gig => {
    const gigPlace = findGigPlace(gig);
    return findLocationFromPlace(gigPlace);
}

export const findGigNode = gig => {
    const gigLocation = findGigLocation(gig);
    return findNodeFromLocation(gigLocation);
}

export const payAmount = pay => {
    let matches = pay.match(/(\d+)([ce])/);
    return matches[1];
}

export const paySymbol = pay => {
    let matches = pay.match(/(\d+)([ce])/);
    return <FontAwesomeIcon icon={matches[2] === 'e' ? faEuroSign : faCentSign}/>
}

export const payAmountWithSymbol = pay => {
    let matches = pay.match(/(\d+)([ce])/);
    const amount = parseInt(matches[1], 10);
    const symbol = matches[2] === 'e' ? faEuroSign : faCentSign;
    return { amount, symbol }
}

export default GigList;