import {Button, Card, Container, Form} from "react-bootstrap";
import React, {useState} from "react";
import {motion, useAnimate} from 'framer-motion';

const stepsVariants = {
    hidden:  { x: '-100vh' },
    visible: { x: 0 },
    exit:    { x: '100vh' }
}

const SignupForm = props => {
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        name: "",
        address: "",
        pronouns: "they/them"
    });

    const [currentStep, setCurrentStep] = useState(0);

    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = e => {
        e.preventDefault();
        // TODO Logic for what will happen after form submission.
    };

    const goNext = e => {
        setCurrentStep(currentStep+1);
        console.log(currentStep);
    }

    const goPrev = e => {
        setCurrentStep(currentStep-1);
        console.log(currentStep);
    }

    return (
        <Container id="signup">
            <Card>
                <Card.Header>
                    Sign Up
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group >
                            <Form.Label>Enter Your Name</Form.Label>
                            <Form.Control type="text" name="fullName" value={formData.name} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>What are Your Feckin' Pronouns?</Form.Label>
                            <Form.Control type="text" name="fullName" value={formData.pronouns} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>Where can we reach you? (email)</Form.Label>
                            <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} />
                        </Form.Group>
                    </Form>
                </Card.Body>
                <Card.Footer>
                    <nav>
                        <Button type="button" onClick={goPrev} style={{display:currentStep!==0? "block" : "none" }}>Back</Button>
                        <Button type="button" onClick={goNext}>Next</Button>
                    </nav>
                </Card.Footer>
            </Card>
        </Container>
    );
}

export default SignupForm;
