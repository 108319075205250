import React from 'react';
import {faCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {setFilter} from "../../store/map";
import {useDispatch, useSelector} from "react-redux";

const CityMapFilters = ({ modes = ['st'] }) => {
    const mapState = useSelector(state => state.map);
    const dispatch = useDispatch();

    const filterToggle = e => {
        const mode = e.currentTarget.getAttribute('data-mode');
        // dispatch(toggleFilter(mode));
        dispatch(setFilter(mode));
    }

    return (
        <div className="city-map-filters modeIcons">
            {['tr','st','fw'].map(mode => {
                const className = `tr-dot tr-${mode} ${mapState.filters[mode]? " selected" : ""}`
                return (
                    <div className="filterToggle" onClick={filterToggle} data-mode={mode} key={mode}>
                        <FontAwesomeIcon icon={faCircle} fixedWidth className={className} />
                    </div>
                )
            })}
        </div>
    )
}

export default CityMapFilters;