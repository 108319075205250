import React, {useState} from 'react';
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import CityMapOverlay from "./CityMapOverlay";
import './CityMap.css';
import { ReactComponent as Map } from '../../assets/cityMap.svg';
import {useSelector} from "react-redux";
import CityMapKey from "./CityMapKey";

const CityMap = () => {
    const gameState = useSelector(state => state.game);
    const mapState = useSelector(state => state.map);
    const [scale, setScale] = useState(2);

    const handleZoomChange = (transformState) => {
        console.log('handleZoomChange', transformState.scale);
        setScale(scale);
    }

    const defaultSize = 10;
    const labelStyle = {
        fontSize: `${defaultSize/scale}px`
    }

    return (
        <div className="cityMap">
            <TransformWrapper
                defaultScale={4}
                defaultPositionX={500}
                defaultPositionY={500}
                limitToBounds={true}
                minScale={3}
                maxScale={5}
                onZoomChange={handleZoomChange}
                // onChange={(transformState) => handleZoomChange(transformState)}
            >
                <TransformComponent>
                    <Map width="1000px" height="1000px" viewBox="0 0 2000 2000" id="Map" />
                    <CityMapOverlay props={gameState} style={labelStyle} />
                </TransformComponent>
            </TransformWrapper>
            <CityMapKey />
        </div>
    );
}

export default CityMap;