import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import LandingPage from "./pages/Landing";
import AboutPage from "./pages/About";
import ContactPage from "./pages/Contact";
import SignupPage from "./pages/Signup/SignupPage";
import Footer from "./components/ui/Footer";
import MainPage from "./pages/Main";

function App() {
    return (
        <Router style={{ width: 100, height: 100 }}>
            <Routes>
                <Route exact path='/' element={<LandingPage />} />
                <Route path='/about' element={<AboutPage />} />
                <Route path='/contact' element={<ContactPage />} />
                <Route path='/signup' element={<SignupPage />} />
                <Route path='/main' element={<MainPage />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;
