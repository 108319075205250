
export const dijkstra = (graph, startNode, endNode) => {
    let distances = { };
    let predecessors = { };
    let unvisitedNodes = Object.keys(graph);

    distances[startNode] = 0;
    unvisitedNodes.forEach(node => {
        if (node !== startNode) distances[node] = Infinity;
    });

    while (unvisitedNodes.length > 0) {
        let currentNode = unvisitedNodes.reduce((closestNode, node) =>
            distances[node] < distances[closestNode] ? node : closestNode, unvisitedNodes[0]);

        console.log(currentNode);
        if (currentNode === endNode) break;

            unvisitedNodes = unvisitedNodes.filter(node => node !== currentNode);
        for (let neighbor in graph[currentNode]) {
            let distanceToNeighbor = distances[currentNode] + graph[currentNode][neighbor];
            if (distanceToNeighbor < distances[neighbor]) {
                distances[neighbor] = distanceToNeighbor;
                predecessors[neighbor] = currentNode;
            }
        }
    }

    let shortestPath = [endNode];
    while (endNode !== startNode) {
        endNode = predecessors[endNode];
        shortestPath.unshift(endNode);
    }

    return shortestPath;
}

class PriorityQueue {
    constructor() { this.queue = []; }

    enqueue(value, priority) {
        this.queue.push({ value, priority });
        this.sort();
    };

    dequeue() {
        return this.queue.shift();
    };

    sort() {
        this.queue.sort((a, b) => a.priority - b.priority);
    };
}

export const weightedDijkstra = (graph, startNode, endNode) => {
    const distances = {};
    const previousNodes = {}
    const queue = new PriorityQueue();

    queue.enqueue(startNode, 0);
    distances[startNode] = 0;

    let count = 0;
    const maxCount = 100;
    while (queue.queue.length) {
        if (count++ > maxCount) {
            console.error(`Max Loops (${maxCount}) exceeded! (${count}`);
            break;
        }
        const { value: currentNode } = queue.dequeue();
        for (const neighbor in graph[currentNode]) {
            const distanceToNeighbor = distances[currentNode] + graph[currentNode][neighbor]['dist'];
            if (!distances[neighbor] || distanceToNeighbor < distances[neighbor]) {
                distances[neighbor] = distanceToNeighbor;
                previousNodes[neighbor] = currentNode;
                queue.enqueue(neighbor, distances[neighbor]);
            }
        }
    }

    let currentNode = endNode;
    const shortestPath = [currentNode];
    while (currentNode !== startNode) {
        currentNode = previousNodes[currentNode];
        shortestPath.unshift(currentNode);
    }

    return shortestPath;
}

export const calculateTravelTime = ({ dist, mode }) => {
    const speeds = { st: 50, fw: 100, tr: 150 }; // todo: hacky, fix
    const perLeg = { st: 3, fw: 0, tr: 5 }
    return dist/speeds[mode]+perLeg[mode];
};

export const calculateTotalTravelTime = route => {
    let total = 0;
    let segments = [];

    route.connections.forEach(connection => {
        total += calculateTravelTime(connection);
        segments.push(connection);
    })

    return { segments, total };
}