import React, {useEffect, useRef} from 'react';
import {highlightNodes, selectNode} from "../../store/map";
import travelNodes from "../../data/travelNodes.json";
import {useDispatch, useSelector} from "react-redux";

const LocationList = ({ locations, currentLocation }) => {
    const mapState = useSelector(state => state.map);
    return (
        <div className="places-list">
            <ul>
                { locations.map((location, i) => {
                    const here = location.id === currentLocation;
                    const selected = mapState.selectedLocation === location;
                    return <LocationItem key={i} location={location} here={here} selected={selected} />
                })}
            </ul>
        </div>
    )
}

const LocationItem = ({location, currentLocation, i, ...props}) => {
    const ref = useRef(null);
    const dispatch = useDispatch();
    const locationNode = travelNodes.find(node => node.id === location.id);
    const {selected=false, here=false} = props;

    useEffect(() => {
        if(ref.current !== null ) {
            // ref.current.addEventListener('mouseover', handleHover);
            // return () => ref.current.removeEventListener('mouseover', handleHover);
        }
    }, []);

    if (!locationNode) return null;

    const nodeIds = [ locationNode.id ];

    const handleHover = e => {
        nodeIds && dispatch(highlightNodes(nodeIds));
    }

    const handleUnhover = e => {
        dispatch(highlightNodes([]));
    }

    const handleClick = e => {
        dispatch(selectNode(locationNode))
    }

    const classNames = ['location-list-item', locationNode.id];
    selected && classNames.push('selected');
    here && classNames.push('here');

    return (
        <li key={i}
            className={classNames.join(' ')}
            onMouseEnter={handleHover}
            onMouseLeave={handleUnhover}
            onClick={handleClick}
            ref={ref}>
            {location.name}
        </li>
    )

}

export const findNodeFromLocation = location => travelNodes.find(node => location.id === node.id);

export default LocationList;